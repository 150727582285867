import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

import {
  ContractListItemCardComponent,
  ContractUserInfoComponent,
  ContractValidityComponent,
} from '@mp/contract-management/common/ui';
import { Condition } from '@mp/contract-management/conditions/domain';

import { ConditionInfoComponent } from '../condition-info/condition-info.component';
import { ConditionSupplierComponent } from '../condition-supplier/condition-supplier.component';

@Component({
  selector: 'mpctm-condition-list-item',
  standalone: true,
  templateUrl: './condition-list-item.component.html',
  styleUrls: ['./condition-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,

    MatButtonModule,

    ContractUserInfoComponent,
    ConditionInfoComponent,
    ContractValidityComponent,
    ContractListItemCardComponent,
    ConditionSupplierComponent,
  ],
})
export class ConditionListItemComponent {
  @Input({ required: true }) condition!: Condition;
}
